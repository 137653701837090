import React, { useState } from 'react';
import './global.css';
import './SuggestPlayerForm.css';
import Footer from './Footer';


const SuggestPlayerForm = () => {
    const [playerName, setPlayerName] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // State to track error message
    const [loading, setLoading] = useState(false);

    const TIMEOUT = 30000; // Set timeout duration in milliseconds

    const fetchWithTimeout = async (url, options, timeout = TIMEOUT) => {
        const controller = new AbortController();
        const { signal } = controller;

        const timeoutId = setTimeout(() => {
            controller.abort(); // Abort the fetch request after the timeout
        }, timeout);

        try {
            const response = await fetch(url, { ...options, signal });

            // Clear the timeout once the response is received
            clearTimeout(timeoutId);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return response;
        } catch (error) {
            if (error.name === 'AbortError') {
                throw new Error('Request timed out');
            }
            throw error; // Re-throw the error for further handling
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Reset error message on each submit
        setLoading(true); // Start loading

        const API_URL = process.env.REACT_APP_API_URL;

        try {
            const response = await fetchWithTimeout(`${API_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ playerName }),
            });

            // Check if the response is OK (status in the range 200-299)
            if (response.ok) {
                const responseData = await response.json(); // Parse the response body as JSON
                console.log(responseData)

                // Check if the server returned a success or failure message
                if (responseData.success === false) {
                    console.log('server returned false')
                    // If the response indicates the player already exists
                    setErrorMessage(responseData.message); // Use the message from the server
                } else {
                    // If successful, reset the form
                    setSubmitted(true);
                    setPlayerName('');
                }
            } else {
                // If the response is not OK, extract the error message
                const errorData = await response.json();
                const errorMessage = errorData.message || 'Failed to submit suggestion. Please try again.';
                setErrorMessage(errorMessage); // Set the error message
            }
        } catch (error) {
            setErrorMessage(error.message || 'Network error. Please try again.'); // Handle network errors
        } 
        finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className='sContainer'>
            <div className='stop-section'>
                <span className='sugg-span'>Suggest a Player</span>

                <p className='sugg-p'>
                    Think we missed someone? Type and submit their name, and we will review adding them to the all-time list. Please ensure your suggestion meets the criteria — don’t suggest just anyone. We’re looking for players who have consistently performed at a high level, such as those who have made it to the grand finals with respectable earnings and are known people within the competitive scene.
                </p>

                {!submitted && (
                    <form onSubmit={handleSubmit}>
                        <label id="text" className='type-label'>
                            Enter Player Name:
                        </label>
                        <div className="input-containeric1">
                            <input
                                className='input'
                                type="text"
                                value={playerName}
                                onChange={(e) => setPlayerName(e.target.value)}
                                required
                            />
                        </div>

                        <button className='sugg-filter-buttons' type="submit" disabled={loading}>
                            {loading ? (
                                <span className="spinner"></span> // Loading spinner
                            ) : (
                                'Submit'
                            )}
                        </button>

                        {/* Display error message if there's an error */}
                        {errorMessage && <p className='error-message'>{errorMessage}</p>}
                    </form>
                )}

                {submitted && <p className='thank-you-message'>Suggestion Submitted Successfully</p>}
            </div>
        </div>
    );
};

export default SuggestPlayerForm;
